<template>
  <div>
    <modal name="tipoTicketEdit" height="auto" width="900" :scrollable="true" :clickToClose="false">
      <CloseModal :close="close"/>
      <div class="modalbox">
        <div class="modalbox-content">
          <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="getTitleModal(item) + descSingular">
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">
                      <span>Chave</span>
                      <fa-icon icon="info-circle" class="ml-1" :title="tooltipChave" />
                    </label>
                    <input class="form-control" v-model="item.chave"/>
                    <div class="message">{{ validation.firstError('item.chave') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Descrição</label>
                    <input class="form-control" v-model="item.desc"/>
                    <div class="message">{{ validation.firstError('item.desc') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Menu</label>
                    <select class="form-control" v-model="item.menu">
                      <option v-for="(desc, key) in tipoTicketMenu" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.menu') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Usa transportadora</label>
                    <select class="form-control" v-model="item.usa_transportadora">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.usa_transportadora') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Usa produto</label>
                    <select class="form-control" v-model="item.usa_produto">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.usa_produto') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Destaca</label>
                    <select class="form-control" v-model="item.destaca">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.destaca') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Usa consumidor final</label>
                    <select class="form-control" v-model="item.usa_consumidor_final">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.usa_consumidor_final') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Usa mensagem</label>
                    <select class="form-control" v-model="item.usa_mensagem">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.usa_mensagem') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Usa nota</label>
                    <select class="form-control" v-model="item.usa_nota">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.usa_nota') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Usa nota produto</label>
                    <select class="form-control" v-model="item.usa_nota_produto">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.usa_nota_produto') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Usa prorrogação</label>
                    <select class="form-control" v-model="item.usa_prorrogacao">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.usa_prorrogacao') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Usa assunto</label>
                    <select class="form-control" v-model="item.usa_assunto">
                      <option v-for="(desc, key) in tipoTicketConfiguracao" :value="key" :key="key">
                        {{ desc }}
                      </option>
                    </select>
                    <div class="message">{{ validation.firstError('item.usa_prorrogacao') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">Gera espelho devolução</label>
                    <select class="form-control" v-model="item.gera_espelho_devolucao">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.gera_espelho_devolucao') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow">
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">{{ getDescricaoAcessoSistema('acessab2b') }}</label>
                    <select class="form-control" v-model="item.acessab2b">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.acessab2b') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">{{ getDescricaoAcessoSistema('acessacallcenter') }}</label>
                    <select class="form-control" v-model="item.acessacallcenter">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.acessacallcenter') }}</div>
                  </div>
                </div>
                <div class="col-1-3">
                  <div class="form-group">
                    <label for="">{{ getDescricaoAcessoSistema('acessafv') }}</label>
                    <select class="form-control" v-model="item.acessafv">
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                    <div class="message">{{ validation.firstError('item.acessafv') }}</div>
                  </div>
                </div>
              </div>
              <div class="xrow edit-buttons">
                <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                        v-on:click="handleSubmit">
                  Salvar
                </button>
                &nbsp;
                <button class="button" v-on:click="close">Fechar</button>
              </div>
            </CTab>
          </CTabs>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';
import CloseModal from '../../../../components/CloseModal';

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "*obrigatório"
  },
});

export default {
  name: 'tipoTicketEdit',
  props: ['item', 'update', 'close', 'descSingular', 'tipoTicketMenu', 'tipoTicketConfiguracao', 'getDescricaoAcessoSistema'],
  components: { CloseModal },
  validators: {
    'item.chave'                  : (value) => Validator.value(value).required(),
    'item.desc'                   : (value) => Validator.value(value).required(),
    'item.menu'                   : (value) => Validator.value(value).required(),
    'item.usa_transportadora'     : (value) => Validator.value(value).required(),
    'item.usa_produto'            : (value) => Validator.value(value).required(),
    'item.destaca'                : (value) => Validator.value(value).required(),
    'item.usa_consumidor_final'   : (value) => Validator.value(value).required(),
    'item.usa_mensagem'           : (value) => Validator.value(value).required(),
    'item.usa_nota'               : (value) => Validator.value(value).required(),
    'item.usa_nota_produto'       : (value) => Validator.value(value).required(),
    'item.usa_prorrogacao'        : (value) => Validator.value(value).required(),
    'item.gera_espelho_devolucao' : (value) => Validator.value(value).required(),
    'item.acessab2b'              : (value) => Validator.value(value).required(),
    'item.acessacallcenter'       : (value) => Validator.value(value).required(),
    'item.acessafv'               : (value) => Validator.value(value).required(),
  },
  computed: {
    tooltipChave() {
      return 'O campo chave é utilizado para definir o identificador único do tipo de ticket. ' +
          'Esse valor será empregado em relacionamentos e automações dentro do sistema. ' +
          'Apenas letras maiúsculas e o caractere de underline (_) são permitidos neste campo. ' +
          'Antes de alterar este campo, certifique-se de compreender o impacto de suas modificações.';
    }
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          this.update();
        }
      });
    },
    getTitleModal(item) {
      if (item.id) {
        return 'Editando ';
      }
      return 'Criando ';
    }
  }
};
</script>
